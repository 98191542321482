import React from 'react';
import { Routes, Route } from 'react-router-dom';

import LM from './directory/LM';
import Verify from './directory/Verify';
import NotFound from './directory/NotFound';




function AppRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<LM />} />
      <Route path="/LM" element={<LM />} />
      <Route path="/verify" element={<Verify />} />






      
      <Route path="*" element={<NotFound />} />
      
      
      
      
      </Routes>
  );
}

export default AppRoutes;
