import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Typeeffect from '../usercomponents/Typeeffect';

function Home() {
  return (
    <div className="container-home">
      <Helmet>
        <title>Lectuemeet | Learning Beyond Borders | Professional Certification</title>
        <meta name="description" content="Your page description" />
        {/* Add more meta tags as needed */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Lecturemeet",
              "url": "https://www.yourwebsite.com/",
              "logo": "https://www.yourwebsite.com/logo.png"
            }
          `}
        </script>
        
        <meta property="og:title" content="Lectuemeet | Learning Beyond Borders | Professional Certification" />
        <meta property="og:description" content="Your page description" />
        <meta property="og:image" content="https://www.yourwebsite.com/og-image.jpg" />
        <meta property="og:url" content="https://www.yourwebsite.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      





      <section className="mt-8">
        <Typeeffect />
      </section>



      <section class="verify-background-section">
  <div class="verify-container">
    <div class="verify-content">
      <h2>Credibility</h2>
      <p>Through a well designed Certificate, each validates the skills and knowledge acquired by the learner, which can be used to enhance their resume or professional profile and even advance career.</p>
    </div>
  </div>
</section>


<section className="about-us">
      <div className="container">
        <div className="row">
          <div className="col-md-6 about-lm">
            <h2>Why Lecturemeet Certificate</h2>
          </div>
          <div className="col-md-6 about-lm">
            <div>
              <h2>Building <span className='text-black'>Authenticity</span></h2>
              <p>Certificates are awarded upon successful completion of courses or programs offered by Lecturemeet.
<br />
<br />
              Certificates are professionally designed to reflect the credibility and standards of Lecturemeet

              </p>
            </div>
          </div>
          <div className='col-md-6 about-lm'>
            <p>Each certificate typically includes the learner's name, the course or program completed, the date of completion, and a unique certificate ID</p>
            <Link to="/Verify" className="btn btn-primary" style={{ textDecoration: 'none' }}>Verify Certificate</Link>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 benefits-certification">
            <h2>Benefits of Certification</h2>
            <ul>
              <li>Enhances professional credibility</li>
              <li>Improves career opportunities and advancement</li>
              <li>Provides a transparent and reliable way to confirm the credentials of learners</li>
              <li>Provides a competitive advantage in the job market</li>
              <li>Demonstrates commitment to personal and professional development</li>
              <li>Our certification helps prevent the misuse of certificates and ensures that only genuine certificates are recognized.</li>
              <li>Builds trust among employers and other stakeholders regarding the authenticity of the learner’s credentials on Lecturemeet</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 verification-purpose">
            <h2>Purpose of Verification</h2>
            <p>The verification process on Lecturemeet ensures the authenticity of the certification and learner, also validates the skills and knowledge acquired through Lecturemeet courses. </p>
          </div>
          <div className="col-md-6 verification-process">
            <h2>Verification Process</h2>
            <p>The verification process includes a thorough review of the course completion records, assessment results, and other relevant documentation to confirm the legitimacy of the certification. This process is designed to maintain the integrity and value of Lecturemeet certifications.</p>
          </div>
        </div>
      </div>
    </section>











    </div>
  );
}

export default Home;
