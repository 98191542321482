import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Lecturemeet.png';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeMenu);
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Lecturemeet Logo" className="logo-image" />
          </Link>
        </div>
        <div className={`menu-toggle ${showMenu ? 'active' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`nav-menu ${showMenu ? 'active' : ''}`}>
        <li><Link to="https://lecturemeet.com/About" onClick={toggleMenu}>About</Link></li>
        <li><Link to="/Verify" onClick={toggleMenu}>Certificate</Link></li>
          <li className="dropdown">
            <Link to="#" aria-haspopup="true">Programmes <i className="fa fa-angle-down"></i></Link>
            <ul ref={dropdownRef} className="dropdown-menu">
              <li><Link to="https://lecturemeet.com/Pre-degree" onClick={toggleMenu}>Pre-Degree Programme</Link></li>
              <li><Link to="https://lecturemeet.com/Legal-and-compliance" onClick={toggleMenu}>Legal & Compliance</Link></li>
              <li><Link to="https://lecturemeet.com/Corporate-and-banking-sector" onClick={toggleMenu}>Corporate & Finance</Link></li>
              <li><Link to="https://lecturemeet.com/Human-resource-and-product-development-studies" onClick={toggleMenu}>Human Resource </Link></li>
              <li><Link to="https://lecturemeet.com/Product-Management" onClick={toggleMenu}>Product Management</Link></li>
              <li><Link to="https://lecturemeet.com/Scholarship" onClick={toggleMenu}>Scholarship</Link></li>
            </ul>
          </li>
          <li className="dropdown hidenav">
            <Link to="#" aria-haspopup="true">Program learning Models <i className="fa fa-angle-down"></i></Link>
            <ul ref={dropdownRef} className="dropdown-menu">
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>Compliance</Link></li>
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>AML/CFT</Link></li>
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>Due process & corporate governance for private sectors</Link></li>
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>Due process & corporate governance for public sectors</Link></li>
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>Mergers & Acquisitions</Link></li>
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>Taxation</Link></li>
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>Risk Management</Link></li>
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>Human Resource Management</Link></li>
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>Corporate Governance</Link></li>
              <li><Link to="https://lecturemeet.com/Programme" onClick={toggleMenu}>Product management</Link></li>
            </ul>
          </li>
          <li><Link to="https://organisation.lecturemeet.com" onClick={toggleMenu}>For Organisations</Link></li>
          <li><Link to="https://lecturemeet.com/Login" className="login-button" onClick={toggleMenu} style={{color: "#ffffff"}}>Account</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
