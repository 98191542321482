import React, { useEffect } from 'react';
import Typed from 'typed.js';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  useEffect(() => {
    const strings = [
      'Onbaording teams',
      'Building team growth',
      'Verify learners credentials',
      'Empower growth',
    ];

    const options = {
      strings: strings,
      typeSpeed: 50,
      backSpeed: 30,
      loop: true,
      backDelay: 2000,
    };

    const typed = new Typed('.typing-element', options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="hero-section">
      <h1><span>Creating</span> Reliable <br />Learning Without <span>Borders</span></h1>
      <h4>For and with</h4>
      <p>
        <span className="typing-element"></span>
      </p>
      <Link to="/verify" className="btn btn-primary" style={{ textDecoration: 'none' }}>
  Verify certificate
</Link>
    </div>
  );
};

export default HeroSection;
