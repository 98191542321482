import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDqOJIIVMikxNfvkMD68lz0TkODpcLhjtQ",
  authDomain: "business-lecturemeet.firebaseapp.com",
  projectId: "business-lecturemeet",
  storageBucket: "business-lecturemeet.appspot.com",
  messagingSenderId: "983569980918",
  appId: "1:983569980918:web:f360ec6f8f0fae65003bd7",
  measurementId: "G-KXLSXKTWPE"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const VerifyCertificate = () => {
  const [certificateId, setCertificateId] = useState('');
  const [certificateData, setCertificateData] = useState(null);

  const fetchCertificate = async () => {
    try {
      const docRef = doc(db, 'Certificate-Business-Team-learners', certificateId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCertificateData(docSnap.data());
      } else {
        alert('Certificate not found');
      }
    } catch (error) {
      console.error('Error fetching certificate:', error);
      alert('Error fetching certificate');
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
        <input
          type="text"
          value={certificateId}
          onChange={(e) => setCertificateId(e.target.value)}
          placeholder="Enter Business ID and name on certifcate (e.g., 20126_Maxwell John-Doe)"
          style={{ padding: '10px', fontSize: '16px', border: '1px solid #ccc', borderRadius: '4px' }}
        />
        <button
          onClick={fetchCertificate}
          style={{
            marginTop: '10px',
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Verify Certificate
        </button>
        <p style={{ marginTop: '10px', fontSize: '14px', color: '#666' }}>
          For business team certificate confirmation, use the business ID and the name of the certificate 
          (e.g., 20126_Maxwell John-Doe).
        </p>
      </div>
      {certificateData && (
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr>
              <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc', backgroundColor: '#f2f2f2' }}>Name</th>
              <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc', backgroundColor: '#f2f2f2' }}>Certificate Number</th>
              <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc', backgroundColor: '#f2f2f2' }}>Organization Name</th>
              <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc', backgroundColor: '#f2f2f2' }}>Date of Certificate</th>
              <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc', backgroundColor: '#f2f2f2' }}>Course</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>{certificateData.fullName}</td>
              <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>{certificateData.certificateNumber}</td>
              <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>{certificateData.organization}</td>
              <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>{new Date(certificateData.timestamp).toLocaleDateString()}</td>
              <td style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>{certificateData.courseName}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default VerifyCertificate;
